<!-- 刘志坤 -->
<!-- 盖印人鼠标移入展示 -->
<template>
  <div>
    <a-popover trigger="hover" placement="bottom" v-if="list">
      <template #content>
        <div class="popover-box">
          <div class="popover-item" v-for="(item, index) in list" :key="item">
            <span>{{ item.staffName }}</span><i v-if="index != list.length - 1">，</i>
          </div>
        </div>
      </template>
      <slot></slot>
    </a-popover>
    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const state = reactive({
      list: props.list
    })
    return {
      ...toRefs(state)
    }
  },
}
</script>
<style lang='less' scoped>
// 蒙层展示
.popover-box {
  max-width: 300px;

  .popover-item {
    // max-width: 84px!important;
    display: inline;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    
    span {
      color: #323233;
      font-size: 14px;
      // max-width: 84px!important;
      // display: inline-block;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}
</style>
